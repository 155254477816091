export default {
  "home.main.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seamos más productivos"])},
  "home.banner.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "home.banner.tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tareas realizadas"])},
  "button.add.goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar objetivo"])},
  "button.add.task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar tarea"])},
  "button.update.task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar tarea"])},
  "add.goal.page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar objetivo"])},
  "update.goal.page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar objetivo"])},
  "add.goal.form.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título de la meta"])},
  "add.goal.title.error.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, establece un título para tu objetivo."])},
  "add.goal.deadline.error.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, establezca una fecha límite para su objetivo"])},
  "add.goal.form.deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de finalización del objetivo"])},
  "add.goal.form.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor llene todos los campos"])},
  "add.goal.form.error.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este objetivo ya está registrado, agregue otro"])},
  "add.goal.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal added successfully"])},
  "goals.to.start.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metas para começar"])},
  "task.filter.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
  "task.filter.to.do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que hacer"])},
  "task.filter.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hecho"])},
  "task.form.error.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta tarea ya está registrada, agregue otra"])},
  "add.task.page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar nueva tarea"])},
  "update.task.page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar tarea"])},
  "add.task.form.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título de la tarea"])},
  "add.task.form.title.error.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, establezca un título para su tarea"])},
  "add.task.form.error.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta tarea ya está registrada, agregue otra"])},
  "add.task.form.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción de la tarea"])},
  "add.task.form.description.error.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, establece una descripción para tu tarea"])},
  "add.task.form.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor llene todos los campos"])},
  "add.task.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarea añadida con éxito"])},
  "update.task.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarea actualizada correctamente"])},
  "modal.confirmation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación"])},
  "delete.task.confirmation.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está seguro de que desea eliminar esta tarea?"])},
  "delete.task.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
  "delete.task.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "delete.task.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarea eliminada con éxito"])},
  "goals.in.progress.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metas en progreso"])},
  "delete.goal.confirmation.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está seguro de que desea eliminar este objetivo? También se eliminarán todas las tareas."])},
  "delete.goal.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
  "delete.goal.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "delete.goal.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta eliminada con éxito"])},
  "button.update.goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar objetivo"])},
  "update.goal.form.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor llene todos los campos"])},
  "update.goal.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo actualizado con éxito"])},
  "new.goal.add.task.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar nuevas tareas"])},
  "new.goal.add.task.modal.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desea agregar nuevas tareas a este objetivo ahora? También puede agregarlos más tarde, su objetivo aparecerá en la sección \"Objetivos para comenzar\"."])},
  "new.goal.add.task.modal.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])},
  "new.goal.add.task.modal.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luego"])},
  "task.list.no.tasks.all.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no se han agregado tareas, haga clic en el botón de abajo para agregar una nueva tarea"])},
  "task.list.no.tasks.todo.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay más tareas que hacer, haga clic en el botón de abajo para agregar una nueva tarea"])},
  "task.list.no.tasks.done.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay tareas realizadas todavía, termine sus tareas para verlas aquí"])},
  "goal.page.until.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasta que"])},
  "login.logo.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goals Planner"])},
  "login.google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión con Google"])},
  "login.or.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])},
  "login.form.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "login.form.email.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su correo electrónico"])},
  "login.form.email.invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca un correo electrónico válido"])},
  "login.form.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
  "login.form.password.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su contraseña"])},
  "login.form.password.invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una minúscula y un número"])},
  "login.form.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
  "login.form.no.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes una cuenta?"])},
  "login.form.create.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una"])},
  "login.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión exitoso"])},
  "login.form.user.not.found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario no encontrado"])},
  "login.form.wrong.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña incorrecta"])},
  "login.form.user.disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario deshabilitado"])},
  "login.form.error.generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al iniciar sesión"])},
  "register.form.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una cuenta"])},
  "register.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro exitoso"])},
  "register.form.error.generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al registrarse"])},
  "register.form.email.already.in.use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este correo electrónico ya está en uso"])},
  "register.form.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
  "user.dialog.logout.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres cerrar sesión?"])},
  "user.dialog.logout.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "user.dialog.logout.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "user.logout.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierre de sesión exitoso"])},
  "home.goals.to.start.empty.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no hay objetivos para comenzar, haga clic en el botón a continuación para agregar un nuevo objetivo"])},
  "home.goals.in.progress.empty.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no hay objetivos en progreso, seleccione un objetivo de la sección \"Objetivos para comenzar\" para comenzar a trabajar en él"])}
}