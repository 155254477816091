export default {
  "home.main.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vamos ser mais produtivos"])},
  "home.banner.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "home.banner.tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarefas feitas"])},
  "button.add.goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar meta"])},
  "button.add.task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar tarefa"])},
  "button.update.task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar tarefa"])},
  "add.goal.page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar nova meta"])},
  "update.goal.page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar meta"])},
  "add.goal.form.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título da meta"])},
  "add.goal.title.error.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, defina um título para sua meta"])},
  "add.goal.deadline.error.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, defina um prazo para sua meta"])},
  "add.goal.form.deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de término da meta"])},
  "add.goal.form.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor preencha todos os campos"])},
  "add.goal.form.error.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta meta já está registrada, adicione outra"])},
  "add.goal.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta adicionada com sucesso"])},
  "goals.to.start.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metas para começar"])},
  "task.filter.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas"])},
  "task.filter.to.do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fazer"])},
  "task.filter.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizadas"])},
  "task.form.error.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta tarefa já está registrada, adicione outra"])},
  "add.task.page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar nova tarefa"])},
  "update.task.page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar tarefa"])},
  "add.task.form.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título da Tarefa"])},
  "add.task.form.title.error.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, defina um título para sua tarefa"])},
  "add.task.form.error.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta tarefa já está registrada, adicione outra"])},
  "add.task.form.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição da tarefa"])},
  "add.task.form.description.error.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, defina uma descrição para sua tarefa"])},
  "add.task.form.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor preencha todos os campos"])},
  "add.task.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarefa adicionada com sucesso"])},
  "update.task.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarefa atualizada com sucesso"])},
  "modal.confirmation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação"])},
  "delete.task.confirmation.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja excluir esta tarefa?"])},
  "delete.task.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])},
  "delete.task.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "delete.task.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarefa excluída com sucesso"])},
  "goals.in.progress.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metas em andamento"])},
  "delete.goal.confirmation.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja excluir esta meta? Todas as tarefas também serão excluídas."])},
  "delete.goal.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir"])},
  "delete.goal.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "delete.goal.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "button.update.goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar meta"])},
  "update.goal.form.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor preencha todos os campos"])},
  "update.goal.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta atualizada com sucesso"])},
  "new.goal.add.task.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta atualizada com sucesso"])},
  "new.goal.add.task.modal.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseja adicionar novas tarefas a essa meta agora? Você também pode adicioná-los mais tarde, sua meta será listada na seção \"Metas para começar\"."])},
  "new.goal.add.task.modal.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
  "new.goal.add.task.modal.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais tarde"])},
  "task.list.no.tasks.all.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma tarefa adicionada ainda, clique no botão abaixo para adicionar uma nova tarefa"])},
  "task.list.no.tasks.todo.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há mais tarefas para fazer, clique no botão abaixo para adicionar uma nova tarefa"])},
  "task.list.no.tasks.done.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma tarefa concluída ainda, termine suas tarefas para vê-las aqui"])},
  "goal.page.until.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Até"])},
  "login.logo.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goals Planner"])},
  "login.google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar com o Google"])},
  "login.or.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
  "login.form.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "login.form.email.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, insira seu email"])},
  "login.form.email.invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, insira um email válido"])},
  "login.form.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
  "login.form.password.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, insira sua senha"])},
  "login.form.password.invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua senha deve ter pelo menos 8 caracteres, incluindo letras maiúsculas, minúsculas e números"])},
  "login.form.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
  "login.form.no.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não tem uma conta?"])},
  "login.form.create.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie uma"])},
  "login.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login realizado com sucesso"])},
  "login.form.user.not.found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário não encontrado, verifique seu email e senha"])},
  "login.form.wrong.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha incorreta, verifique sua senha"])},
  "login.form.user.disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário desativado, entre em contato com o administrador"])},
  "login.form.error.generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao fazer login, tente novamente mais tarde"])},
  "register.form.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie sua conta"])},
  "register.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta criada com sucesso"])},
  "register.form.error.generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao criar conta, tente novamente mais tarde"])},
  "register.form.email.already.in.use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este email já está em uso, tente outro"])},
  "register.form.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar conta"])},
  "user.dialog.logout.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja sair?"])},
  "user.dialog.logout.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair"])},
  "user.dialog.logout.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "user.logout.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout realizado com sucesso"])},
  "home.goals.to.start.empty.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda não há metas para começar, clique no botão abaixo para adicionar uma nova meta"])},
  "home.goals.in.progress.empty.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Ainda não há metas em andamento. Selecione uma meta na seção \"Metas para começar\" para começar a trabalhar nela"])}
}