export default {
  "home.main.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s become more productive"])},
  "home.banner.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "home.banner.tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tasks done"])},
  "button.add.goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Goal"])},
  "button.add.task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Task"])},
  "button.update.task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Task"])},
  "add.goal.page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new goal"])},
  "update.goal.page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update goal"])},
  "add.goal.form.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal Title"])},
  "add.goal.title.error.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, set a title for your goal"])},
  "add.goal.deadline.error.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, set a deadline for your goal"])},
  "add.goal.form.deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal Finish Date"])},
  "add.goal.form.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill all the fields"])},
  "add.goal.form.error.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Goal is already registered, please add another"])},
  "add.goal.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal added successfully"])},
  "goals.to.start.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goals to start"])},
  "task.filter.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "task.filter.to.do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To do"])},
  "task.filter.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "task.form.error.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Task is already registered, please add another"])},
  "add.task.page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new task"])},
  "update.task.page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update task"])},
  "add.task.form.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Title"])},
  "add.task.form.title.error.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, set a title for your task"])},
  "add.task.form.error.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Task is already registered, please add another"])},
  "add.task.form.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Description"])},
  "add.task.form.description.error.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, set a description for your task"])},
  "add.task.form.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill all the fields"])},
  "add.task.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task added successfully"])},
  "update.task.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task updated successfully"])},
  "modal.confirmation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "delete.task.confirmation.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this task?"])},
  "delete.task.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "delete.task.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "delete.task.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task deleted successfully"])},
  "goals.in.progress.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goals in progress"])},
  "delete.goal.confirmation.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this goal? All tasks will be deleted too."])},
  "delete.goal.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "delete.goal.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "delete.goal.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal deleted successfully"])},
  "button.update.goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Goal"])},
  "update.goal.form.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill all the fields"])},
  "update.goal.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal updated successfully"])},
  "new.goal.add.task.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new tasks"])},
  "new.goal.add.task.modal.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to add new tasks to this goal now? You can add them later too, your goal will be listed on \"Goals to start\" section."])},
  "new.goal.add.task.modal.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "new.goal.add.task.modal.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Later"])},
  "task.list.no.tasks.all.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tasks added yet, click on the button below to add a new task"])},
  "task.list.no.tasks.todo.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more tasks to do, click on the button below to add a new task"])},
  "task.list.no.tasks.done.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tasks done yet, finish your tasks to see them here"])},
  "goal.page.until.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until"])},
  "login.logo.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goals Planner"])},
  "login.google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with Google"])},
  "login.or.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "login.form.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "login.form.email.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required"])},
  "login.form.email.invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address"])},
  "login.form.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "login.form.password.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a password"])},
  "login.form.password.invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must have 8 characters including at least a capital letter and a number"])},
  "login.form.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "login.form.no.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account?"])},
  "login.form.create.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create one"])},
  "login.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are logged in"])},
  "login.form.user.not.found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found, please check your credentials or create an account"])},
  "login.form.wrong.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong password, please check your credentials"])},
  "login.form.user.disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been disabled, please contact the administrator"])},
  "login.form.error.generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please check your credentials and try again"])},
  "register.form.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your account"])},
  "register.form.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been created successfully"])},
  "register.form.error.generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while creating your account"])},
  "register.form.email.already.in.use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is already used, please choose another one"])},
  "register.form.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
  "user.dialog.logout.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to logout?"])},
  "user.dialog.logout.button.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "user.dialog.logout.button.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "user.logout.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are logged out"])},
  "home.goals.to.start.empty.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No goals to start yet, click on the button below to add a new goal"])},
  "home.goals.in.progress.empty.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No goals in progress yet, select a goal from \"Goals to start\" section to start working on it"])}
}